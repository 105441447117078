export default {
  init() {
    // JavaScript to be fired on all pages

    // $(window).on('load', function() {
    //   $('body').css('margin-top', $('.banner').outerHeight())
    // })
    $('.js-search-button').on('click', function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
      } else {
        $('.nav-icons > li > a:not(.contact_button)').removeClass('active')
        $(this).addClass('active')
      }
      $('.nav-hamburger').removeClass('opened')
      $('.searchform-wrapper').toggleClass('opened')
    })

    $('.js-hamburger_button').on('click', function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
      } else {
        $('.nav-icons > li > a:not(.contact_button)').removeClass('active')
        $(this).addClass('active')
      }
      $('.searchform-wrapper').removeClass('opened')
      $('.nav-hamburger').toggleClass('opened')
    })

    function smooth_scroll(link, e) {
      if (e) e.preventDefault()
      var hash = false
      if (!link && window.location.hash) {
        hash = window.location.hash
      } else if (link && link.indexOf('#') == 0) {
        hash = link
      } else if (
        link &&
        location.pathname.replace(/^\//, '') ==
          link.pathname.replace(/^\//, '') &&
        location.hostname == link.hostname
      ) {
        hash = link.hash
      }
      if (hash) {
        $('html, body').animate(
          {
            scrollTop:
              $(hash).offset().top - $('header.banner').outerHeight() - 40,
          },
          600
        )
        history.replaceState(null, null, hash)
      }
    }

    $(
      'a[href^="#"]:not([data-vc-accordion],[data-vc-tabs],[href="#"],[href="#0"],[href^="#fancy-"],[role="tab"])'
    ).on('click', function(e) {
      smooth_scroll($(this).attr('href'), e)
    })
    if (window.location.hash) {
      smooth_scroll(window.location.hash)
    }

    // VC full-width row
    function resizeFullWidthRow() {
      $('[data-vc-full-width="true"]').each(function() {
        $(this).css({
          width: document.body.clientWidth,
          marginLeft: -document.body.clientWidth / 2,
        })
        if (!$(this).attr('data-vc-stretch-content')) {
          let padding =
            (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
          $(this).css({
            'padding-left': padding,
            'padding-right': padding,
          })
        }
      })
    }

    resizeFullWidthRow()

    $(window).on('resize', function() {
      resizeFullWidthRow()
    })

    $('.loop-item').on('hover', function() {
      $(this)
        .find('.entry-summary')
        .stop(true, true)
        .slideToggle('600')
    })

    $('.main-nav-button').on('click', function() {
      $(this)
        .closest('.banner')
        .toggleClass('active')
    })

    $('ul.navbar-nav li.menu-item.no_action').on('click', function(e) {
      e.preventDefault()
    })

    function Menu360() {
      $('.main-nav-button').on('click tap', function() {
        $('html').toggleClass('menu360-open')
        $(this).toggleClass('open-nav-button')
        $('#menu360').toggleClass('open-toggle')
      })

      $(window).on('resize', function() {
        var w = $(this)
        if (w.width() > 991) {
          $('html').removeClass('menu360-open')
          $('.navbar-toggle').removeClass('open-nav-button')
          $('#menu360').removeClass('open-toggle')
        }
      })

      $('#menu360 ul li.menu-item-has-children > a').on('click tap', function(
        e
      ) {
        e.preventDefault()
        if (
          $(this)
            .parent()
            .hasClass('open')
        ) {
          $(this)
            .next()
            .animate(
              {
                height: 0,
              },
              500
            )
          $(this)
            .parent()
            .removeClass('open')
        } else {
          $(this)
            .next()
            .animate(
              {
                height: $(this)
                  .next()
                  .get(0).scrollHeight,
              },
              500,
              function() {
                $(this).height('auto')
              }
            )
          $(this)
            .parent()
            .addClass('open')
        }
      })
    }
    Menu360()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
