var mapContainer = $('.map')
var maps = []
var styles = {
  light: [
    {
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#f5f5f5' }],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#c9c9c9' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
  ],
  dark: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#233856',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#8ec3b9',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1a3646',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#64779e',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#334e87',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#283d6a',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#233856',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.government',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.medical',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#233856',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#11274a',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#98a5be',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1d2c4d',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#505f74',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#255763',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#b0d5ce',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#023e58',
        },
      ],
    },
    {
      featureType: 'road.local',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#98a5be',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1d2c4d',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#283d6a',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#3a4762',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#505f74',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#4e6d70',
        },
      ],
    },
  ],
}

function initMap($map, i) {
  let $markers = $map.find('.marker')
  let colorScheme = $map.data('color-scheme')
  var args = {
    zoom: 16,
    mapTypeId: google.maps.MapTypeId.ROADMAP, // eslint-disable-line
    disableDefaultUI: true,
    styles: styles[colorScheme],
  }
  // console.log($markers) // eslint-disable-line
  maps[i] = new google.maps.Map($map[0], args) // eslint-disable-line
  maps[i].markers = []
  // add markers
  $markers.each(function(index, el) {
    add_marker($(el), maps[i])
  })

  center_map(maps[i])
}

function add_marker($marker, map) {
  // get lat & lng
  var latlng = new google.maps.LatLng($marker.data('lat'), $marker.data('lng')) // eslint-disable-line

  // create marker
  // eslint-disable-next-line
  var marker = new google.maps.Marker({
    // eslint-disable-line
    position: latlng,
    map: map,
    icon: $marker.data('marker-icon'), //uncomment if you use custom marker
  })

  // add to array
  map.markers.push(marker)

  // if marker contains HTML, add it to an infoWindow
  if ($marker.html()) {
    // create info window
    // eslint-disable-next-line
    var infowindow = new google.maps.InfoWindow({
      // eslint-disable-line
      content: $marker.html(),
    })

    // infowindow.open(map, marker)
    // show info window when marker is clicked
    // eslint-disable-next-line
    google.maps.event.addListener(marker, 'click', function() {
      // eslint-disable-line
      infowindow.open(map, marker)
    })
  }
}

function center_map(map) {
  // vars
  var bounds = new google.maps.LatLngBounds() // eslint-disable-line

  // only 1 marker?
  // if (map.markers.length == 1) {
  //   // set center of map
  //   map.setCenter(bounds.getCenter())
  //   // map.setZoom(16)
  // } else {
  // loop through all markers and create bounds
  $.each(map.markers, function(i, marker) {
    var latlng = new google.maps.LatLng( // eslint-disable-line
      marker.position.lat(),
      marker.position.lng()
    )
    bounds.extend(latlng)
  })
  // }
  if (map.markers.length == 1) {
    map.setCenter(bounds.getCenter())
    // map.setZoom(12)
  } else {
    // fit to bounds
    map.fitBounds(bounds)
  }
}

var timeOut = undefined

var googleMapsAllowed =
  typeof window.BorlabsCookie === 'object' &&
  window.BorlabsCookie.checkCookieConsent('googlemaps') &&
  !/Chrome-Lighthouse/.test(navigator.userAgent) // don't render map in lighthouse test
if (googleMapsAllowed !== undefined) {
  if (googleMapsAllowed) {
    clearTimeout(timeOut)
    mapContainer.each(function(index) {
      $(this).data('id', index)
      initMap($(this), index)
    })
  } else {
    timeOut = setTimeout(function() {
      mapContainer.each(function(index) {
        $(this).data('id', index)
        initMap($(this), index)
      })
    }, 1000)
  }
}

//console.log('googleMapsAllowed:' + googleMapsAllowed) // eslint-disable-line

$(window).load(function() {
  centerMaps()
})

$(document).ready(function() {
  // mapContainer.each(function(index) {
  //   $(this).data('id', index)
  //   initMap($(this), index)
  // })

  $('body').on('click', '[data-vc-accordion]', () => {
    centerMaps()
  })
})

function centerMaps() {
  $('.map').each(function(index, el) {
    center_map(maps[$(el).data('id')])
  })
}

// 50.0030585
// 8.2568775
